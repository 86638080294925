import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { Form, Formik } from "formik";
import { Dialog, Transition } from "@headlessui/react";

import Datepicker from "../../stories/DatePicker";
import Dropdown from "../../stories/Dropdown";
import CheckList from "../../stories/CheckList";
import Button from "../../stories/Button";
import { CustomToast } from "../../stories/Toast";

import { genderOptions } from "../../Constants";
import { useOptions } from "../../services/BasicDetails";
import { Validations } from "../../constants/validations";
import { UserService } from "../../services/UserService";

const AdditionalDetailsModal = ({ open, onClose, publicProfileData }) => {
  const initialValues = {
    dob: publicProfileData?.dob && moment(publicProfileData?.dob, "DD/MM/YYYY"),
    gender: publicProfileData?.gender,
    graduation_id: publicProfileData?.graduation_id,
    industry_ids: publicProfileData?.industry_ids,
    professional_ids: publicProfileData?.professional_ids,
  };

  const { options } = useOptions(["graduation", "professional", "industry"], {
    revalidateOption: { revalidateOnFocus: false, revalidateIfStale: false },
  });

  const onFormSubmit = async (values) => {
    try {
      const params = {
        user: { ...values },
      };
      const res = await UserService.updateAdditionalDetails(params);
      console.log(res);
      CustomToast.success(res.message);
      onClose();
    } catch (e) {
      CustomToast.error(e.error_messages[0]);
    }
  };

  return (
    <React.Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          open={open}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen p-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the QuizIntroModal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full p-4 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded shadow-xl sm:my-8 sm:align-middle sm:max-w-xl lg:max-w-3xl sm:w-full sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-3 text-3xl font-bold leading-6 text-theme-black-color"
                >
                  Update Your Profile
                  <input
                    type="text"
                    className="h-0 w-0 border-0 focus:ring-0 focus:outline-0 leading-[0px] p-0 font-[0px]"
                    autoFocus={true}
                  />
                </Dialog.Title>
                <p className="mt-5 text-lg text-theme-black-300 leading-4">
                  Share the below details to help us create better learning path
                  for you
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    Validations.popup.additionalDetailsValidationSchema
                  }
                  onSubmit={onFormSubmit}
                  enableReinitialize
                >
                  {({ values, ...formProps }) => {
                    return (
                      <Form className="w-full">
                        <div className="flex flex-col w-full gap-6 mt-6 bg-white rounded-sm shadow-sm lg:shadow-none">
                          <div className="flex flex-col items-center space-x-0 space-y-6 md:flex-row md:space-x-6 md:space-y-0">
                            <Datepicker
                              name="dob"
                              placeholder="Birth Date"
                              label="Date of Birth"
                              filterDate={(d) =>
                                d <
                                new Date(
                                  new Date() - 18 * 365 * 24 * 60 * 60 * 1000
                                )
                              }
                              maxDate={
                                new Date(
                                  new Date() - 18 * 365 * 24 * 60 * 60 * 1000
                                )
                              }
                              className="mt-1 w-full border border-gray-200 py-[7px] rounded-sm shadow-sm"
                              isRequired
                            />
                            <Dropdown
                              name="gender"
                              id="gender"
                              type="text"
                              label="Gender"
                              placeholder="Select Gender"
                              options={genderOptions}
                              displayCurrentOption={true}
                              displayKey="gender"
                              idKey="id"
                              className="w-full"
                              isRequired
                            />
                          </div>
                          <div className="flex flex-col items-center space-x-0 space-y-6 md:flex-row md:space-x-6 md:space-y-0">
                            <Dropdown
                              name="graduation_id"
                              id="graduation_id"
                              type="text"
                              label="Which of the following graduation stream have you completed ?"
                              placeholder="Select Graduation Stream"
                              renderValue={(values) =>
                                _.head(
                                  _.filter(
                                    options["graduation"],
                                    (e) =>
                                      e.id ===
                                      values?.graduation_id?.field_of_study_id
                                  ) ?? []
                                )?.value ?? ""
                              }
                              displayCurrentOption={true}
                              options={options["graduation"]}
                              displayKey="value"
                              idKey="id"
                              className="w-full"
                              isRequired
                            />
                          </div>
                          <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
                            <div className="col-span-full">
                              <CheckList
                                label="Which Professional Exams / Qualifications / Certifications have you completed ?"
                                isRequired={true}
                                name="professional_ids"
                                formProps={formProps}
                                values={values}
                                displayCurrentOption={true}
                                options={options["professional"]}
                                displayKey="value"
                                idKey="id"
                              />
                            </div>
                            <div className="col-span-full">
                              <CheckList
                                label="Which Industry Exams have you passed ?"
                                isRequired={true}
                                name="industry_ids"
                                formProps={formProps}
                                values={values}
                                displayCurrentOption={true}
                                options={options["industry"]}
                                displayKey="value"
                                idKey="id"
                              />
                            </div>
                          </div>
                          <div className="flex justify-center md:justify-end">
                            <div className="hidden md:block md:w-full" />
                            <Button
                              buttonStyle="primary"
                              height="40px"
                              width="100%"
                              className="w-full uppercase order-1 lg:order-2"
                              disabled={formProps.isSubmitting}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

AdditionalDetailsModal.defaultProps = {
  open: false,
  className: "",
};

AdditionalDetailsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  publicProfileData: PropTypes.any,
};

export default AdditionalDetailsModal;
