import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";

import QPFPSkeleton from "../../../stories/QPFPDashboard/QPFPSkeleton";
import Loader from "../../Shimmer/Loader";
import { ErrorScreen } from "../../../stories/ErrorScreen";
import Button from "../../../stories/Button";
import ConfirmProgramSelectionModal from "../../Modals/ConfirmProgramSelectionModal.js";

import TempProgramThumbnail from "../../../assets/images/pro_member_course_thumnail.png";
import { BulletList } from "../../../stories/BulletList";
import { useAcePrograms } from "../../../services/CourseDetails";
import { CourseService } from "../../../services/CourseService";
import { CustomToast } from "../../../stories/Toast";
import classNames from "classnames";

/**
 * Component for ACE Program
 * @return {React.ReactElement} ACE Program
 */
function ACEProgram({
  courseId,
  title,
  description,
  benefits,
  status,
  setSelectedAvailableProgram,
  setShowProgramSelectionModal,
  disabled = false,
}) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        status === "completed" &&
        navigate(`/qpfp-certificant/ace-program-and-tests/${courseId}/`)
      }
      className={classNames(
        `flex flex-col justify-start items-start space-y-2 p-4 bg-white border border-[#E5E7EB] rounded-lg${
          status === "completed" ? " cursor-pointer" : " cursor-default"
        }`,
        disabled && "grayscale"
      )}
    >
      <img
        src={TempProgramThumbnail}
        alt="Program thumbnail"
        className="object-cover h-44 rounded-md"
      />

      <p className="font-medium text-lg leading-6">{title}</p>

      <p className="font-normal text-sm pb-2">{description}</p>

      {status !== "completed" && (
        <div className="self-stretch flex flex-col space-y-3 p-2 !mb-4 bg-[#F9F9F9]">
          <p className="font-semibold text-xs">What you will get</p>
          <BulletList list={benefits} />
        </div>
      )}

      <Button
        onClick={
          disabled
            ? null
            : () => {
                if (status === "not_registered") {
                  setSelectedAvailableProgram(courseId);
                  setShowProgramSelectionModal(true);
                } else {
                  navigate(
                    `/qpfp-certificant/ace-program-and-tests/${courseId}/`
                  );
                }
              }
        }
        className="!mt-auto !rounded-md"
        disabled={disabled}
      >
        {status === "not_registered" ? "Select a Program" : "Continue Course"}
      </Button>
    </div>
  );
}

ACEProgram.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  courseId: PropTypes.number,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string,
  setSelectedAvailableProgram: PropTypes.any,
  setShowProgramSelectionModal: PropTypes.any,
};

/**
 * Component for ACE Program and Tests Page
 * @return {React.ReactElement} ACE Program and Tests Page
 */
function ACEProgramAndTests() {
  const navigate = useNavigate();

  const {
    programs,
    allowRegistration,
    loading: isLoading,
    error,
  } = useAcePrograms();

  const [selectedAvailableProgram, setSelectedAvailableProgram] =
    useState(null);
  const [showProgramSelectionModal, setShowProgramSelectionModal] =
    useState(false);

  const addCoursePrompt = async () => {
    try {
      const response = await CourseService.createUserCourse(
        selectedAvailableProgram
      );
      CustomToast.success(response.message ?? "Course Added Successfully");

      navigate(
        `/qpfp-certificant/ace-program-and-tests/${selectedAvailableProgram}/`
      );
    } catch (e) {
      console.log(e);
      CustomToast.error(e.error_messages[0]);
    } finally {
      setSelectedAvailableProgram(null);
      setSelectedAvailableProgram(false);
    }
  };

  if (isLoading) {
    return (
      <QPFPSkeleton showFooter={true}>
        <div className="absolute top-0 flex items-center justify-center w-full h-screen ">
          <Loader />
        </div>
      </QPFPSkeleton>
    );
  }
  if (error) {
    return (
      <QPFPSkeleton showFooter={true}>
        <ErrorScreen />
      </QPFPSkeleton>
    );
  }

  return (
    <QPFPSkeleton showFooter={true}>
      <div className="flex flex-col space-y-6 w-full py-10 relative z-0">
        <div className="flex flex-col space-y-4 px-6">
          <h1 className="font-bold text-2xl">ACE Program and Tests</h1>
          <p className="text-sm text-theme-black-300 mt-3">
            Advanced Continuous Education (ACE) helps QPFP Certificants to
            UpSkill themselves in one advanced area of personal finance. QPFP
            Certicants can choose to select any one ACE Program of Network FP
            per year.
          </p>
        </div>

        {programs?.some(
          (program) =>
            program?.has_registered &&
            program?.course_completion_percentage < 100
        ) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">ACE Pursuing</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {programs
                ?.filter(
                  (program) =>
                    program?.has_registered &&
                    program?.course_completion_percentage < 100
                )
                ?.map((program, index) => (
                  <ACEProgram
                    key={`ace-program-${index}`}
                    courseId={program?.id}
                    title={program?.name}
                    description={program?.description}
                    benefits={program?.contents}
                    status={"in_progress"}
                    setSelectedAvailableProgram={setSelectedAvailableProgram}
                    setShowProgramSelectionModal={setShowProgramSelectionModal}
                  />
                ))}
            </div>
          </div>
        )}

        {programs?.some(
          (program) =>
            program?.has_registered &&
            program?.course_completion_percentage === 100
        ) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">ACE Completed</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {programs
                ?.filter(
                  (program) =>
                    program?.has_registered &&
                    program?.course_completion_percentage === 100
                )
                ?.map((program, index) => (
                  <ACEProgram
                    key={`ace-program-${index}`}
                    courseId={program?.id}
                    title={program?.name}
                    description={program?.description}
                    benefits={program?.contents}
                    status={"completed"}
                    setSelectedAvailableProgram={setSelectedAvailableProgram}
                    setShowProgramSelectionModal={setShowProgramSelectionModal}
                  />
                ))}
            </div>
          </div>
        )}

        {programs?.some((program) => !program?.has_registered) && (
          <div className="flex flex-col space-y-4 px-6">
            <h3 className="font-medium text-lg">ACE Available</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {programs
                ?.filter((program) => !program?.has_registered)
                ?.map((program, index) => (
                  <ACEProgram
                    key={`ace-program-${index}`}
                    courseId={program?.id}
                    title={program?.name}
                    description={program?.description}
                    benefits={program?.contents}
                    status={"not_registered"}
                    setSelectedAvailableProgram={setSelectedAvailableProgram}
                    setShowProgramSelectionModal={setShowProgramSelectionModal}
                    disabled={!allowRegistration}
                  />
                ))}
            </div>
          </div>
        )}

        <ConfirmProgramSelectionModal
          open={showProgramSelectionModal}
          onConfirm={addCoursePrompt}
          onClose={() => {
            setSelectedAvailableProgram(null);
            setShowProgramSelectionModal(false);
          }}
        />
      </div>
    </QPFPSkeleton>
  );
}

export default ACEProgramAndTests;
